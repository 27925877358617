import { createSelector } from 'reselect';
import { rootState } from '../../reducers/index';
import { ILanguage, IDictionary } from '../../interfaces/index';
import { getLanguageTexts, getLanguageKeys, getLanguage } from './operations';

export const getSourceLanguageTexts = createSelector<
  rootState,
  ILanguage | undefined,
  IDictionary<string> | undefined
>(
  state =>
    state.translations.languages.find(
      language => language.languageId === state.translationsUi.sourceLanguageId
    ),
  getLanguageTexts
);

export const getDestinationLanguageTexts = createSelector<
  rootState,
  ILanguage | undefined,
  IDictionary<string> | undefined
>(
  state =>
    state.translations.languages.find(
      language =>
        language.languageId === state.translationsUi.destinationLanguageId
    ),
  getLanguageTexts
);

export const getDestinationLanguageSelector = createSelector<
  rootState,
  ILanguage[],
  number,
  ILanguage
>(
  state => state.translations.languages,
  state => state.translationsUi.destinationLanguageId,
  getLanguage
);

export const getLanguageKeysSelector = createSelector<
  rootState,
  string[],
  boolean,
  ILanguage,
  string[]
>(
  state => state.translations.keys.items,
  state => state.translationsUi.toggleOnlyMissing,
  state => getDestinationLanguageSelector(state),
  getLanguageKeys
);

export const getSourceLanguageSelector = createSelector<
  rootState,
  ILanguage[],
  number,
  ILanguage
>(
  state => state.translations.languages,
  state => state.translationsUi.sourceLanguageId,
  getLanguage
);
