import { except } from '../../utility/index';
import { ILanguage, IDictionary } from '../../interfaces/index';

export const getLanguageTexts = (language?: ILanguage) => {
  if (!language || !language.languageTexts) return;

  const dictionary = {} as IDictionary<string>;

  for (let i = 0; i < language.languageTexts.length; i++) {
    const item = language.languageTexts[i];
    dictionary[item.key] = item.text;
  }

  return dictionary;
};

export const getLanguageKeys = (
  keys: string[],
  toggleOnlyMissing: boolean,
  language: ILanguage | undefined
) => {
  if (!language) return keys;

  return toggleOnlyMissing
    ? except(keys, language.languageTexts.map(lt => lt.key))
    : keys;
};

export const getLanguage = (languages: ILanguage[], languageId: number) =>
  languages.find(language => language.languageId === languageId)!;
