import { autoinject } from 'aurelia-framework';
import {
  ILanguage,
  ILanguageText,
  IDictionary,
  IRequestState
} from '../../interfaces/index';
import { rootState } from '../../reducers';
import { BaseViewModel } from '../common';
import { TranslationService } from '../../services';
import {
  selectDestinationLanguage,
  toggleOnlyMissing,
  selectSourceLanguage,
  textChanged,
  discardChanges
} from '../../actions/';
import {
  getLanguageKeysSelector,
  getSourceLanguageTexts,
  getDestinationLanguageTexts,
  getSourceLanguageSelector,
  getDestinationLanguageSelector
} from './selectors';
import { getLogger } from 'aurelia-logging';
import { isNone } from '../../utility';
import './translation.css';

interface TranslationState {
  languages: ILanguage[];
  languageRequest: IRequestState;

  showOnlyMissing: boolean;
  isSwitchingSourceLanguage: boolean;

  sourceLanguage: ILanguage;
  destinationLanguage: ILanguage | undefined;

  sourceLanguageTexts?: IDictionary<string>;
  destinationLanguageTexts?: IDictionary<string>;

  languageKeys: string[];

  editedLanguageKeys: ILanguageText[];
}

const mapState = (state: rootState): TranslationState => {
  const languageKeys = getLanguageKeysSelector(state);

  const sourceLanguageTexts = getSourceLanguageTexts(state);
  const destinationLanguageTexts = getDestinationLanguageTexts(state);

  return {
    languageRequest: state.translations.languagesRequest,
    languages: state.translations.languages,
    showOnlyMissing: state.translationsUi.toggleOnlyMissing,
    isSwitchingSourceLanguage: state.translationsUi.isSwitchingSourceLanguage,

    sourceLanguage: getSourceLanguageSelector(state),
    destinationLanguage: getDestinationLanguageSelector(state),

    sourceLanguageTexts,
    destinationLanguageTexts,
    languageKeys,
    editedLanguageKeys: state.translationsUi.editedLanguageTexts
  };
};

@autoinject()
export class TranslationViewModel extends BaseViewModel<TranslationState> {
  constructor(private translationService: TranslationService) {
    super(getLogger('TranslationViewModel'), mapState);
  }

  activate() {
    if (this.state.languages.length > 0) return;

    Promise.all([
      this.translationService.getLanguageKeys(),
      this.translationService.getAllLanguages()
    ]);
  }

  onSourceLanguageChange(languageId: string) {
    this.dispatch(selectSourceLanguage(parseInt(languageId, 10)));
  }

  onDestinationLanguageChange(languageId: string) {
    this.dispatch(selectDestinationLanguage(parseInt(languageId, 10)));
  }

  setSelectedLanguage(id: number) {
    const selectedLanguage = this.state.languages.findIndex(
      lng => lng.languageId == id
    );
    this.dispatch(selectDestinationLanguage(selectedLanguage));
  }

  dispatchTextChanged(languageKey: string, languageId: number, value: string) {
    const { destinationLanguage } = this.state;
    if (value === undefined || isNone(destinationLanguage)) return;

    const languageText = destinationLanguage.languageTexts.find(
      lt => lt.key === languageKey && lt.languageId === languageId
    );

    const pristine = languageText && languageText.text === value;

    const languageTextId = languageText ? languageText.languageTextId : 0;
    const originalText = languageText ? languageText.originalText : '';

    if (!pristine) {
      this.dispatch(
        textChanged({
          languageTextId,
          languageId,
          key: languageKey,
          text: value,
          originalText
        } as ILanguageText)
      );
    }
  }

  saveChanges() {
    this.translationService.saveLanguageTexts(this.state.editedLanguageKeys);
  }

  discardChanges() {
    this.dispatch(discardChanges(this.state.editedLanguageKeys));
  }

  onFilterChange() {
    this.dispatch(toggleOnlyMissing(!this.state.showOnlyMissing));
  }
}
